export function agencyFeePercentage({
  price,
  percentage
}) {
  return price * (percentage / 100) * 1.22;
}
export function agencyFee(args) {
  let fee = 0;

  switch (args.kind) {
    case "PERCENTAGE":
      const {
        price,
        percentage
      } = args;
      fee = agencyFeePercentage({
        price,
        percentage
      });
      break;

    case "NONE":
      fee = 0;
      break;

    case "VALUE":
      fee = args.fee;
      break;
  }

  return Math.max(fee, 0);
}
export function totalAgencyFee(building) {
  if (building.isViaAgent) {
    if (building.hasOverriddenAgencyFee) {
      return agencyFee({
        kind: "VALUE",
        fee: building.overriddenAgentFee
      });
    } else {
      return agencyFee({
        kind: "PERCENTAGE",
        percentage: building.agentPercentageFee,
        price: building.price
      });
    }
  } else {
    return agencyFee({
      kind: "NONE"
    });
  }
}
export function taxes(args) {
  if (args.isPrimaCasa) {
    if (args.isSoldByCompany) {
      return {
        registro: 200,
        catastale: 200,
        ipotecaria: 200,
        VAT: args.price * 4 / 100
      };
    } else {
      return {
        registro: args.renditaCatastale * 115.5 * 2 / 100,
        catastale: 50,
        ipotecaria: 50,
        VAT: 0
      };
    }
  } else {
    if (args.isSoldByCompany) {
      return {
        registro: 200,
        catastale: 200,
        ipotecaria: 200,
        VAT: args.price * 10 / 100
      };
    } else {
      return {
        registro: args.renditaCatastale * 126 * 9 / 100,
        catastale: 50,
        ipotecaria: 50,
        VAT: 0
      };
    }
  }
}
export function taxValues(building) {
  if (building.isSoldByCompany) {
    return taxes({
      price: building.price,
      isPrimaCasa: building.isPrimaCasa,
      isSoldByCompany: building.isSoldByCompany
    });
  } else {
    return taxes({
      isPrimaCasa: building.isPrimaCasa,
      isSoldByCompany: building.isSoldByCompany,
      renditaCatastale: building.renditaCatastale
    });
  }
}
export function mortgage(args) {
  if (args.isPrimaCasa) {
    return {
      impostaSostitutiva: args.mortgage * 0.25 / 100
    };
  } else {
    return {
      impostaSostitutiva: args.mortgage * 2 / 100
    };
  }
}