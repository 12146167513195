import { template as _$template } from "../_snowpack/pkg/solid-js/web.js";
import { insert as _$insert } from "../_snowpack/pkg/solid-js/web.js";
import { createComponent as _$createComponent } from "../_snowpack/pkg/solid-js/web.js";

const _tmpl$ = _$template(`<section class="mt-4 grid grid-cols-3 gap-2 border p-2 border-gray-200 rounded"><div class="col-span-3 font-bold text-xl flex items-center"><h2 class="inline-block">Oneri Notarili</h2></div><div class="col-span-3 grid grid-cols-3 gap-2"><label class="col-span-2 flex items-center" for="notary">Oneri notarili IVA 22% inclusa</label></div><div class="col-span-3 grid grid-cols-3 gap-2"><label class="col-span-2 flex items-center font-bold" for="notaryTotal">Totale Oneri Notarili</label></div></section>`, 14);

import { useContext } from "../_snowpack/pkg/solid-js.js";
import { Input } from "./input.js";
import { StoreContext } from "./store-context.js";
export function Notary() {
  const [state, setState] = useContext(StoreContext);
  return (() => {
    const _el$ = _tmpl$.cloneNode(true),
          _el$2 = _el$.firstChild,
          _el$3 = _el$2.nextSibling,
          _el$4 = _el$3.firstChild,
          _el$5 = _el$3.nextSibling,
          _el$6 = _el$5.firstChild;

    _$insert(_el$3, _$createComponent(Input, {
      id: "notary",
      symbol: "€",

      get value() {
        return state.building.notaryFee;
      },

      onChange: value => setState("building", "notaryFee", value)
    }), null);

    _$insert(_el$5, _$createComponent(Input, {
      id: "notaryTotal",
      symbol: "€",
      disabled: true,

      get value() {
        return state.building.notaryFee;
      },

      isBold: true
    }), null);

    return _el$;
  })();
}