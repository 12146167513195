import { template as _$template } from "../_snowpack/pkg/solid-js/web.js";
import { setAttribute as _$setAttribute } from "../_snowpack/pkg/solid-js/web.js";
import { effect as _$effect } from "../_snowpack/pkg/solid-js/web.js";
import { insert as _$insert } from "../_snowpack/pkg/solid-js/web.js";

const _tmpl$ = _$template(`<div><div class="relative flex items-center rounded-md shadow-sm overflow-hidden"><span class="absolute pl-1 ml-1"></span><input type="text" placeholder="0"></div></div>`, 7);

const formatter = new Intl.NumberFormat("it-IT", {
  style: "decimal",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});
export function Input(props) {
  const getFormattedValue = () => {
    if (props.value === null) {
      return "-";
    } else {
      return formatter.format(props.value);
    }
  };

  let ref = undefined;
  return (() => {
    const _el$ = _tmpl$.cloneNode(true),
          _el$2 = _el$.firstChild,
          _el$3 = _el$2.firstChild,
          _el$4 = _el$3.nextSibling;

    _$insert(_el$3, () => props.symbol);

    _el$4.addEventListener("change", e => {
      if (props.onChange) {
        const stringWithoutThousandsSeparator = e.currentTarget.value.replace(/\./gi, "").replace(/,/gi, ".");
        const value = parseFloat(stringWithoutThousandsSeparator);

        if (!isNaN(value)) {
          props.onChange(value);
        }

        if (value === props.value) {
          e.currentTarget.value = getFormattedValue();
        }
      }
    });

    _el$4.addEventListener("focus", () => {
      ref?.select();
      ref?.setAttribute("value", typeof props.value === "number" ? props.value.toString() : "0");
    });

    (node => ref = node)(_el$4);

    _$effect(_p$ => {
      const _v$ = props.id,
            _v$2 = "border w-full block focus:ring-indigo-500 focus:border-indigo-500 rounded-md border-gray-300 text-right p-1 disabled:bg-gray-200".concat(props.isBold ? " font-bold" : ""),
            _v$3 = props.disabled,
            _v$4 = getFormattedValue();

      _v$ !== _p$._v$ && _$setAttribute(_el$4, "id", _p$._v$ = _v$);
      _v$2 !== _p$._v$2 && (_el$4.className = _p$._v$2 = _v$2);
      _v$3 !== _p$._v$3 && (_el$4.disabled = _p$._v$3 = _v$3);
      _v$4 !== _p$._v$4 && (_el$4.value = _p$._v$4 = _v$4);
      return _p$;
    }, {
      _v$: undefined,
      _v$2: undefined,
      _v$3: undefined,
      _v$4: undefined
    });

    return _el$;
  })();
}