import { id } from "../utils.js";
export function init() {
  return {
    id: id(),
    price: 100_000,
    description: "",
    isViaAgent: true,
    agentPercentageFee: 3,
    hasOverriddenAgencyFee: false,
    overriddenAgentFee: 0,
    isPrimaCasa: true,
    isSoldByCompany: false,
    renditaCatastale: 1_000,
    hasMortgage: false,
    mortgage: 100_000,
    particaMutuo: 500,
    perizia: 1_000,
    notaryFee: 2_500
  };
}