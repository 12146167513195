import { template as _$template } from "../_snowpack/pkg/solid-js/web.js";
import { effect as _$effect } from "../_snowpack/pkg/solid-js/web.js";
import { insert as _$insert } from "../_snowpack/pkg/solid-js/web.js";
import { createComponent as _$createComponent } from "../_snowpack/pkg/solid-js/web.js";
import { memo as _$memo } from "../_snowpack/pkg/solid-js/web.js";

const _tmpl$ = _$template(`<section class="mt-4 grid grid-cols-3 gap-2 border p-2 border-gray-200 rounded"><label class="col-span-3 font-bold text-xl flex items-center"><input class="h-4 w-4 mr-2 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" type="checkbox"><h2 class="inline-block">Mutuo</h2></label><div class="col-span-3 grid grid-cols-3 gap-2"><label class="col-span-2 flex items-center" for="mortgage">Importo Mutuo</label></div><div class="col-span-3 grid grid-cols-3 gap-2"><label class="col-span-2 flex items-center" for="praticaMutuo">Importo pratica mutuo</label></div><div class="col-span-3 grid grid-cols-3 gap-2"><label class="col-span-2 flex items-center" for="perizia">Perizia dell'immobile</label></div><div class="col-span-3 grid grid-cols-3 gap-2"><label class="col-span-2 flex items-center" for="impostaSostitutiva">Imposta sostitutiva</label></div><div class="col-span-3 grid grid-cols-3 gap-2"><label class="col-span-2 flex items-center font-bold" for="costiMutuo">Totale costi per mutuo</label></div></section>`, 27);

import { useContext } from "../_snowpack/pkg/solid-js.js";
import { Input } from "./input.js";
import { mortgage } from "./store/rules.js";
import { StoreContext } from "./store-context.js";
export function Mortgage() {
  const [state, setState] = useContext(StoreContext);

  const impostaSostitutiva = () => mortgage({
    mortgage: state.building.mortgage,
    isPrimaCasa: state.building.isPrimaCasa
  }).impostaSostitutiva;

  const total = () => {
    return state.building.particaMutuo + state.building.perizia + impostaSostitutiva();
  };

  return (() => {
    const _el$ = _tmpl$.cloneNode(true),
          _el$2 = _el$.firstChild,
          _el$3 = _el$2.firstChild,
          _el$4 = _el$2.nextSibling,
          _el$5 = _el$4.firstChild,
          _el$6 = _el$4.nextSibling,
          _el$7 = _el$6.firstChild,
          _el$8 = _el$6.nextSibling,
          _el$9 = _el$8.firstChild,
          _el$10 = _el$8.nextSibling,
          _el$11 = _el$10.firstChild,
          _el$12 = _el$10.nextSibling,
          _el$13 = _el$12.firstChild;

    _el$3.addEventListener("change", () => setState("building", "hasMortgage", !state.building.hasMortgage));

    _$insert(_el$4, _$createComponent(Input, {
      id: "mortgage",
      symbol: "€",

      get disabled() {
        return !state.building.hasMortgage;
      },

      get value() {
        return state.building.hasMortgage ? state.building.mortgage : null;
      },

      onChange: value => setState("building", "mortgage", value)
    }), null);

    _$insert(_el$6, _$createComponent(Input, {
      id: "praticaMutuo",
      symbol: "€",

      get disabled() {
        return !state.building.hasMortgage;
      },

      get value() {
        return state.building.hasMortgage ? state.building.particaMutuo : null;
      },

      onChange: value => setState("building", "particaMutuo", value)
    }), null);

    _$insert(_el$8, _$createComponent(Input, {
      id: "perizia",
      symbol: "€",

      get disabled() {
        return !state.building.hasMortgage;
      },

      get value() {
        return state.building.hasMortgage ? state.building.perizia : null;
      },

      onChange: value => setState("building", "perizia", value)
    }), null);

    _$insert(_el$10, (() => {
      const _c$ = _$memo(() => !!state.building.hasMortgage, true);

      return _$createComponent(Input, {
        id: "impostaSostitutiva",
        symbol: "€",
        disabled: true,

        get value() {
          return _c$() ? impostaSostitutiva() : null;
        }

      });
    })(), null);

    _$insert(_el$12, (() => {
      const _c$2 = _$memo(() => !!state.building.hasMortgage, true);

      return _$createComponent(Input, {
        id: "costiMutuo",
        symbol: "€",
        disabled: true,

        get value() {
          return _c$2() ? total() : null;
        },

        isBold: true
      });
    })(), null);

    _$effect(() => _el$3.checked = state.building.hasMortgage);

    return _el$;
  })();
}