import { template as _$template } from "../_snowpack/pkg/solid-js/web.js";
import { effect as _$effect } from "../_snowpack/pkg/solid-js/web.js";
import { insert as _$insert } from "../_snowpack/pkg/solid-js/web.js";
import { createComponent as _$createComponent } from "../_snowpack/pkg/solid-js/web.js";
import { memo as _$memo } from "../_snowpack/pkg/solid-js/web.js";

const _tmpl$ = _$template(`<section class="mt-4 grid grid-cols-3 gap-2 border p-2 border-gray-200 rounded"><label class="col-span-3 font-bold text-xl flex items-center"><input class="h-4 w-4 mr-2 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" type="checkbox"><h2 class="inline-block">Agenzia</h2></label><div class="col-span-3 grid grid-cols-3 gap-2"><label class="col-span-2 flex items-center" for="percentage">Di norma le agenzie hanno una provvigione del 3% + IVA 22% sul prezzo di vendita</label></div><div class="col-start-3"></div><div class="col-span-3 grid grid-cols-3 gap-2"><label class="col-span-2 flex items-center" for="agencyFixed"><input id="selectAgencyFixed" type="checkbox" class="h-4 w-4 mr-2 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"><label for="selectAgencyFixed">oppure indica il prezzo forfettario concordato con IVA</label></label></div><div class="col-span-3 grid grid-cols-3 gap-2"><label class="col-span-2 flex items-center font-bold" for="agencyTotal">Totale Costi Agenzia</label></div></section>`, 24);

import { useContext } from "../_snowpack/pkg/solid-js.js";
import { Input } from "./input.js";
import { agencyFee, totalAgencyFee } from "./store/rules.js";
import { StoreContext } from "./store-context.js";
export function Agency() {
  const [state, setState] = useContext(StoreContext);

  const total = () => {
    return totalAgencyFee(state.building);
  };

  return (() => {
    const _el$ = _tmpl$.cloneNode(true),
          _el$2 = _el$.firstChild,
          _el$3 = _el$2.firstChild,
          _el$4 = _el$2.nextSibling,
          _el$5 = _el$4.firstChild,
          _el$6 = _el$4.nextSibling,
          _el$7 = _el$6.nextSibling,
          _el$8 = _el$7.firstChild,
          _el$9 = _el$8.firstChild,
          _el$10 = _el$7.nextSibling,
          _el$11 = _el$10.firstChild;

    _el$3.addEventListener("change", () => setState("building", "isViaAgent", !state.building.isViaAgent));

    _$insert(_el$4, _$createComponent(Input, {
      id: "percentage",
      symbol: "%",

      get disabled() {
        return !state.building.isViaAgent || state.building.hasOverriddenAgencyFee;
      },

      get value() {
        return state.building.isViaAgent && !state.building.hasOverriddenAgencyFee ? state.building.agentPercentageFee : null;
      },

      onChange: value => setState("building", "agentPercentageFee", value)
    }), null);

    _$insert(_el$6, (() => {
      const _c$ = _$memo(() => !!(!state.building.isViaAgent || state.building.hasOverriddenAgencyFee), true);

      return _$createComponent(Input, {
        id: "percentageFee",
        symbol: "€",
        disabled: true,

        get value() {
          return _c$() ? null : agencyFee({
            kind: "PERCENTAGE",
            price: state.building.price,
            percentage: state.building.agentPercentageFee
          });
        }

      });
    })());

    _el$9.addEventListener("change", () => setState("building", "hasOverriddenAgencyFee", !state.building.hasOverriddenAgencyFee));

    _$insert(_el$7, _$createComponent(Input, {
      id: "agencyFixed",
      symbol: "€",

      get disabled() {
        return !state.building.hasOverriddenAgencyFee;
      },

      get value() {
        return state.building.hasOverriddenAgencyFee ? state.building.overriddenAgentFee : null;
      },

      onChange: value => setState("building", "overriddenAgentFee", value)
    }), null);

    _$insert(_el$10, _$createComponent(Input, {
      id: "agencyTotal",
      symbol: "€",
      disabled: true,

      get value() {
        return total();
      },

      isBold: true
    }), null);

    _$effect(_p$ => {
      const _v$ = state.building.isViaAgent,
            _v$2 = state.building.hasOverriddenAgencyFee;
      _v$ !== _p$._v$ && (_el$3.checked = _p$._v$ = _v$);
      _v$2 !== _p$._v$2 && (_el$9.checked = _p$._v$2 = _v$2);
      return _p$;
    }, {
      _v$: undefined,
      _v$2: undefined
    });

    return _el$;
  })();
}