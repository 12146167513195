import * as __SNOWPACK_ENV__ from '../_snowpack/env.js';
import.meta.env = __SNOWPACK_ENV__;

import { createComponent as _$createComponent } from "../_snowpack/pkg/solid-js/web.js";
import { App } from "./app.js";
import { StoreContext } from "./store-context.js";
import { init } from "./store/index.js";
import "./styles/tailwind.css";
import * as dotenv from "../_snowpack/pkg/dotenv.js"; // see https://github.com/motdotla/dotenv#how-do-i-use-dotenv-with-import

import { createRenderEffect } from "../_snowpack/pkg/solid-js.js";
import { render } from "../_snowpack/pkg/solid-js/web.js";
dotenv.config();

function initStore() {
  try {
    const storeStr = atob(window.location.hash.replace(/^#/, ""));
    const storeInitialValue = JSON.parse(storeStr);
    return storeInitialValue;
  } catch (e) {
    console.log("Unable to initiate Store", e);
    return undefined;
  }
}

function saveStateInWindow() {
  try {
    const storeJSONStr = JSON.stringify(store[0], null, 4);
    const base64 = btoa(storeJSONStr);
    window.location.hash = `#${base64}`;
  } catch (e) {
    console.log("Unable to update hash", e);
  }
}

const store = init(initStore());
createRenderEffect(() => {
  saveStateInWindow();
});

(function startTracking() {
  const frag = document.createRange().createContextualFragment(`
  <!-- Google tag (gtag.js) -->
  <script async src="https://www.googletagmanager.com/gtag/js?id=${__SNOWPACK_ENV__.SNOWPACK_PUBLIC_GOOGLE_ANALYTICS_ID}"></script>
  <script>
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', '${__SNOWPACK_ENV__.SNOWPACK_PUBLIC_GOOGLE_ANALYTICS_ID}');
  </script>
  `);
  document.getElementsByTagName("head")[0].appendChild(frag);
})();

const dispose = render(() => _$createComponent(StoreContext.Provider, {
  value: store,

  get children() {
    return _$createComponent(App, {});
  }

}), document.getElementById("app"));
/**
 * Hot Module Replacement (HMR) - Remove this snippet to remove HMR.
 * Learn more: https://www.snowpack.dev/#hot-module-replacement
 *
 * Note: Solid doesn't support state preservation on hot reload as of yet
 */

if (__SNOWPACK_ENV__.MODE === "development") {
  undefined /* [snowpack] import.meta.hot */ .accept();
  undefined /* [snowpack] import.meta.hot */ .dispose(dispose);
}