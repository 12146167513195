import { template as _$template } from "../_snowpack/pkg/solid-js/web.js";
import { effect as _$effect } from "../_snowpack/pkg/solid-js/web.js";
import { insert as _$insert } from "../_snowpack/pkg/solid-js/web.js";
import { createComponent as _$createComponent } from "../_snowpack/pkg/solid-js/web.js";

const _tmpl$ = _$template(`<main class="max-w-2xl m-auto grid p-3"><div class="grid grid-cols-3 gap-2"><h1 class="col-span-2 text-2xl flex-1">Costo Acquisto Casa 🏠</h1><div class="grid gap-4 grid-cols-3"></div></div><p class="italic">Calcola quanto costa veramente comprare casa</p><section class="mt-4 grid grid-cols-3 gap-2 font-bold text-xl"><label class="col-span-2 flex items-center" for="price">Prezzo di acquisto</label></section><section class=""><input class="block mt-2 border w-full focus:ring-indigo-500 focus:border-indigo-500 rounded-md border-gray-300 p-1 disabled:bg-gray-200" id="description" placeholder="descrizione"></section></main>`, 17);

import { useContext } from "../_snowpack/pkg/solid-js.js";
import { Actions } from "./actions.js";
import { Agency } from "./agency.js";
import { Input } from "./input.js";
import { StoreContext } from "./store-context.js";
import { Taxes } from "./taxes.js";
import { Mortgage } from "./mortgage.js";
import { Notary } from "./notary.js";
import { Total } from "./total.js";
export const App = () => {
  const [state, setState] = useContext(StoreContext);
  return (() => {
    const _el$ = _tmpl$.cloneNode(true),
          _el$2 = _el$.firstChild,
          _el$3 = _el$2.firstChild,
          _el$4 = _el$3.nextSibling,
          _el$5 = _el$2.nextSibling,
          _el$6 = _el$5.nextSibling,
          _el$7 = _el$6.firstChild,
          _el$8 = _el$6.nextSibling,
          _el$9 = _el$8.firstChild;

    _$insert(_el$4, _$createComponent(Actions, {}));

    _$insert(_el$6, _$createComponent(Input, {
      id: "price",
      onChange: value => setState("building", "price", value),

      get value() {
        return state.building.price;
      },

      symbol: "€"
    }), null);

    _el$9.addEventListener("change", e => setState("building", "description", e.currentTarget.value));

    _$insert(_el$, _$createComponent(Agency, {}), null);

    _$insert(_el$, _$createComponent(Taxes, {}), null);

    _$insert(_el$, _$createComponent(Mortgage, {}), null);

    _$insert(_el$, _$createComponent(Notary, {}), null);

    _$insert(_el$, _$createComponent(Total, {}), null);

    _$effect(() => _el$9.value = state.building.description);

    return _el$;
  })();
};