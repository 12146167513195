import { template as _$template } from "../_snowpack/pkg/solid-js/web.js";
import { delegateEvents as _$delegateEvents } from "../_snowpack/pkg/solid-js/web.js";
import { setAttribute as _$setAttribute } from "../_snowpack/pkg/solid-js/web.js";
import { effect as _$effect } from "../_snowpack/pkg/solid-js/web.js";

const _tmpl$ = _$template(`<a role="button" class="flex items-center justify-center border p1 rounded text-center">Salva 💾</a>`, 2),
      _tmpl$2 = _$template(`<label for="loadButton" class="flex items-center justify-center border p1 rounded cursor-pointer text-center">Carica 📤<input type="file" name="loadButton" id="loadButton" accept="text/json" role="button" class="hidden"></label>`, 3),
      _tmpl$3 = _$template(`<button class="flex items-center justify-center border p1 rounded text-center">Share 📲</button>`, 2);

import { useContext } from "../_snowpack/pkg/solid-js.js";
import Ajv from "../_snowpack/pkg/ajv.js";
import { StoreContext } from "./store-context.js";
export const Actions = () => {
  const [state, setState] = useContext(StoreContext);

  const dataStr = () => "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(state, null, 2));

  return [(() => {
    const _el$ = _tmpl$.cloneNode(true);

    _$effect(_p$ => {
      const _v$ = dataStr(),
            _v$2 = state.building.description.trim() === "" ? "costo-casa.json" : state.building.description.toLocaleLowerCase() + ".json";

      _v$ !== _p$._v$ && _$setAttribute(_el$, "href", _p$._v$ = _v$);
      _v$2 !== _p$._v$2 && _$setAttribute(_el$, "download", _p$._v$2 = _v$2);
      return _p$;
    }, {
      _v$: undefined,
      _v$2: undefined
    });

    return _el$;
  })(), (() => {
    const _el$2 = _tmpl$2.cloneNode(true),
          _el$3 = _el$2.firstChild,
          _el$4 = _el$3.nextSibling;

    _el$4.addEventListener("change", e => {
      if (e.currentTarget.files?.length === 1) {
        const reader = new FileReader();

        reader.onload = async function (ev) {
          const storeSchema = await fetch("/assets/store/store-schema.json").then(res => res.json());
          const ajv = new Ajv();
          const validate = ajv.compile(storeSchema);

          if (ev.target && ev.target.result) {
            const jsonObj = JSON.parse(ev.target.result.toString());
            const valid = validate(jsonObj);

            if (valid) {
              setState(jsonObj);
            } else {
              console.error(validate.errors);
              alert("Errore nel caricamento del file");
            }
          }
        };

        reader.readAsText(e.currentTarget.files[0]);
      }
    });

    return _el$2;
  })(), (() => {
    const _el$5 = _tmpl$3.cloneNode(true);

    _el$5.$$click = () => {
      navigator.clipboard.writeText(window.location.toString()).then(function () {
        alert("Link copiato, incollalo per condividerlo");
      }, function (err) {
        console.error("Async: Could not copy text: ", err);
      });
    };

    return _el$5;
  })()];
};

_$delegateEvents(["click"]);